let menuBtn = (function () {
    let toggler = document.querySelector('.menu-toggler');
    let menu = document.querySelector('.menu');

    let toggleMenu = function () {
        menu.classList.toggle('menu--open');
        toggler.classList.toggle('menu-toggler--change');
    };

    let init = function () {
        toggler.addEventListener('click', toggleMenu);
        menu.addEventListener('click',function (e) {
            if(e.target.classList.contains('menu__link')) {
                toggleMenu();
            }
        })
    };

    return {
        init: init
    }
}());

export default menuBtn;