const token = '778770494:AAH8wRJBHr9H_cUtsG__GxhAIfzHGP4uvJ4';
const chatId = '-264749404';

export default function sendToTelegram (email, name, message) {
    let data = {
        text: `*Имя:*\n ${name}\n *Email:*\n ${email}\n *Сообщение:*\n ${message}\n`,
        parse_mode: 'Markdown',
        chat_id: chatId
    };

    return fetch(`https://api.telegram.org/bot${token}/sendMessage`, {
        method: 'post',
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
};