import sendToTelegram from './sendToTelegram';

let formValidate = (function () {
    let errorContainer = document.querySelector('.error');
    let submitBtn = document.getElementById('contact_submit');
    let form = document.getElementById('contact_form');
    let name = document.getElementById('contact_name');
    let email = document.getElementById('contact_email');
    let message = document.getElementById('contact_message');
    let successAlert = document.getElementById('successAlert');

    let reset = function () {
        name.classList.remove('with-error');
        email.classList.remove('with-error');
        message.classList.remove('with-error');

        errorContainer.innerHTML = '';
        errorContainer.classList.remove('error--active');
    };

    let showError = function (error) {
        if(error.length>0){
            error.forEach(function (errorMessage) {
                let newSpan = document.createElement('span');
                newSpan.innerHTML = errorMessage;
                errorContainer.appendChild(newSpan);
            });
            errorContainer.classList.add('error--active');
        }
    };

    let isInvalid = function () {

        let error = [];
        let emailRE = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let nameRE = /\D{1,}/;

        if(!nameRE.test(String(name.value).toLowerCase())){
            error.push('Имя введено неверно!');
            name.classList.add('with-error');
        }

        if(!emailRE.test(String(email.value).toLowerCase())){
            error.push('Email введен неверно!');
            email.classList.add('with-error');
        }

        if(message.value.length < 5) {
            error.push('Сообщение должно быть больше 5 символов!');
            message.classList.add('with-error');
        }

        if(error.length > 0) {
            return error;
        }

        return false;

    };

    let onSubmitClick = function (e) {
        e.preventDefault();
        reset();
        let error = isInvalid();
        if(error) {
            showError(error);
            return false;
        }
        let res = sendToTelegram(email.value, name.value, message.value );
        res.then((res) => {
            successAlert.classList.add('alert--active')
        }).catch((res)=>{
            console.log(res);
        })
    };

    let init = function () {
        submitBtn.addEventListener('click',onSubmitClick)
    };

    return {
        init: init
    };
}());

export default formValidate;